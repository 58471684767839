/**
 * @type {UserConfig}
 */
const pluginConfig = {
    //current_lang: 'de',
    autoclear_cookies: true, // default: false
    page_scripts: true, // default: false
    // test: 'ciao',
    // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
    // delay: 0,                               // default: 0
    auto_language: 'document',                     // default: null; could also be 'browser' or 'document'
    // autorun: true,                          // default: true
    force_consent: false,                   // default: false
    hide_from_bots: true,                  // default: false
    // remove_cookie_tables: false             // default: false
    // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
    cookie_expiration: 7,                 // default: 182 (days)
    // cookie_necessary_only_expiration: 182   // default: disabled
    // cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false
    revision: 1,                            // default: 0

    onFirstAction: function (user_preferences, cookie) {
      // callback triggered only once
    },
  
    onAccept: function (cookie) {
      // ...
    },
  
    onChange: function (cookie, changed_preferences) {
      // ...
    },
    //revisions
    revision: 0,

    gui_options: {
        consent_modal: {
            layout: 'bar',
            position: 'middle center',
            transition: 'slide',
            swap_buttons: false
        },
        settings_modal: {
            layout: 'bar',
            position: 'right',
            transition: 'slide'
        }
    },  
    languages: {
      en_at: {
        consent_modal: {
          title: 'We use cookies!',
          description:
            'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent.',
          primary_btn: {
            text: 'Accept all',
            role: 'accept_all', // 'accept_selected' or 'accept_all'
          },
          secondary_btn: {
            text: 'Settings',
            role: 'settings', // 'settings' or 'accept_necessary'
          },
        },
        settings_modal: {
          title: 'Cookie Settings',
          save_settings_btn: 'Save settings',
          accept_all_btn: 'Accept all',
          reject_all_btn: 'Reject all',
          close_btn_label: 'Close',
          blocks: [
            {
              title: 'Cookie usage 📢',
              description:
                'We use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="/en/legal-notice" class="cc-link">privacy policy</a>.',
            },
            {
              title: 'Strictly necessary cookies',
              description:
                'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
              toggle: {
                value: 'necessary',
                enabled: true,
                readonly: true,
              },
            },
            {
              title: 'Performance and Analytics cookies',
              description:
                'These cookies allow the website to remember the choices you have made in the past',
              toggle: {
                value: 'analytics', // your cookie category
                enabled: false,
                readonly: false,
              },
            },
            {
              title: 'More information',
              description:
                'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="/en/contact">contact us</a>.',
            },
          ],
        },
      },
      de_at: {
        consent_modal: {
          title: 'Wir verwenden Cookies!',
          description:
            'Um einen ordnungsgemäßen Betrieb der Website gewährleisten zu können, werden unbedingt erforderliche Cookies verwendet. Tracking-Cookies werden erst nach erteilter Zustimmung genutzt, um ein noch besseres Website-Erlebnis gewährleisten zu können.',
          primary_btn: {
            text: 'Alle akzeptieren',
            role: 'accept_all', // 'accept_selected' or 'accept_all'
          },
          secondary_btn: {
            text: 'Einstellungen',
            role: 'settings', // 'settings' or 'accept_necessary'
          },
        },
        settings_modal: {
          title: 'Cookie-Einstellungen',
          save_settings_btn: 'Einstellungen speichern',
          accept_all_btn: 'Alle akzeptieren',
          reject_all_btn: 'Alle ablehnen',
          close_btn_label: 'Schließen',
          blocks: [
            {
              title: 'Cookie-Nutzung 📢',
              description:
                'Um die grundlegenden Funktionen der Website sicherstellen zu können, verwenden wir Cookies und verbessern so laufend Ihr Online-Erlebnis. Sie können sich jederzeit für jede Kategorie entscheiden. Für weitere Informationen zu Cookies und anderen sensiblen Daten besuchen Sie nachstehenden Link zu unseren <a href="/de/datenschutzeklarung" class="cc-link">Datenschutzrichtlinien</a>.',
            },
            {
              title: 'Unbedingt erforderliche Cookies',
              description:
                'Diese Cookies sind unerlässlich für den ordnungsgemäßen Betrieb dieser Website. Ohne diese Cookies funktioniert diese Website nicht ordnungsgemäß.',
              toggle: {
                value: 'necessary',
                enabled: true,
                readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
              },
            },
            {
              title: 'Leistungs- und Analyse-Cookies',
              description:
                'Diese Cookies ermöglichen es der Website, sich an bereits getroffenen Einstellungen zu erinnern und diese für eine höhere Bedienungsfreundlichkeit einzusetzen.',
              toggle: {
                value: 'analytics', // your cookie category
                enabled: false,
                readonly: false,
              },
            },
            {
              title: 'Mehr Informationen',
              description:
                'Für alle Anfragen in Bezug auf unsere Cookie-Richtlinien und der Auswahlmöglichkeiten <a class="cc-link" href="/de/kontakt">kontaktieren Sie uns</a> hier.',
            },
          ],
        },
      },
    },
    
  };
  
  export default pluginConfig;
  